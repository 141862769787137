import React, { useState, useEffect, Fragment } from "react"
import axios from "axios"
import Moment from "react-moment"
import "moment-timezone"
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
import "semantic-ui-css/semantic.min.css"
import { Pagination } from "semantic-ui-react"
import "../../assets/css/paginationlist.css"
import DateTime from "@nateradebaugh/react-datetime"
import "@nateradebaugh/react-datetime/css/react-datetime.css"
import * as moment from "moment"
import "moment/locale/th"
import { checkPermission } from "../../helper/functions"
import User from "../../libs/admin"
import Spinner from "react-bootstrap/Spinner"
import ModalExport from "./ModalExport"

var ReactBsTable = require("react-bootstrap-table")
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const BoontanjaiDashboard = (props) => {
	const user = User.getCookieData()
	const [data, setData] = useState([])
	const [activePage, setActivePage] = useState(1)
	const [allPage, setallPage] = useState("")
	const [createdateGte, setCreatedateGte] = useState("")
	const [createdateLt, setCreatedateLt] = useState("")
	const [totalCount, setTotalCount] = useState(0)
	const [isLoading, setIsLoading] = useState(false)

	const [partner, setPartner] = useState("all")
	const [status, setStatus] = useState("all")
	const [fulfillmentStatus, setFulfillmentStatus] = useState("all")
	const [shipmentStatus, setShipmentStatus] = useState("all")
	const [paymentStatus, setPaymentStatus] = useState("all")

	const [zipurl, setZipurl] = useState("")
	const [searchAll, setSearchAll] = useState("")
	const [isSearch, setIsSearch] = useState(false)
	const [modalExport, setModalExport] = useState(false)

	const LinkInvoicePDF = (cell, row) => {
		if (row.invoice_pdf !== "-") {
			return (
				<a href={row.invoice_pdf} target="_blank" rel="noopener noreferrer">
					View PDF
				</a>
			)
		} else {
			return row.invoice_pdf
		}
	}
	const Link = (cell) => {
		if (!cell) return "-"
		if (cell === "-") return cell
		return (
			<a href={cell} target="_blank" rel="noopener noreferrer">
				View
			</a>
		)
	}

	useEffect(() => {
		const checked = checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "finance_edit", "admin"], "or")
		if (checked) {
			async function fetchData() {
				setIsLoading(true)
				const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/boontanjai/order/list?v=` + Math.random() * 1000, {
					withCredentials: true,
				})
				setTotalCount(data.totalCount)
				setallPage(data.pages)
				setData(data.data)
				console.log(data.data)
				setZipurl(`${process.env.REACT_APP_API_ROOT}/admin/download-zip`)
				setIsLoading(false)
			}
			fetchData()
		} else {
			props.history.push("/")
		}
	}, [])

	const carlendarcreatedateLt = (date) => {
		var d1 = new Date(date)
		var d2 = new Date(createdateGte)
		var compare = d1.getTime() < d2.getTime()
		if (compare) {
			setCreatedateLt(new Date())
		} else {
			setCreatedateLt(date)
		}
	}
	const carlendarcreatedateGte = (date) => {
		var d1 = new Date(date)
		var d2 = new Date(createdateLt)
		var compare = d1.getTime() > d2.getTime()
		if (compare) {
			setCreatedateGte(new Date())
		} else {
			setCreatedateGte(date)
		}
	}

	const formSearchQuery = () => {
		let searchQuery = ""
		if (searchAll.length > 0) {
			searchQuery += `&searchAll=${searchAll}`
		}
		if (createdateGte.length > 0 && createdateLt.length > 0 && createdateGte !== "Invalid date" && createdateLt !== "Invalid date") {
			searchQuery += `&create_dateSearchgte=${createdateGte}&create_dateSearchlt=${createdateLt}`
		}
		if (partner.length > 0 && partner !== "all") {
			searchQuery += `&partner=${partner}`
		}
		if (status.length > 0 && status !== "all") {
			searchQuery += `&status=${status}`
		}
		if (fulfillmentStatus.length > 0 && fulfillmentStatus !== "all") {
			searchQuery += `&fulfillment_status=${fulfillmentStatus}`
		}
		if (shipmentStatus.length > 0 && shipmentStatus !== "all") {
			searchQuery += `&shipment_status=${shipmentStatus}`
		}
		if (paymentStatus.length > 0 && paymentStatus !== "all") {
			searchQuery += `&payment_status=${paymentStatus}`
		}
		return searchQuery
	}

	const pagination = async (e, pageInfo) => {
		setActivePage(pageInfo.activePage)
		setIsLoading(true)
		if (isSearch !== true) {
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/boontanjai/order/list?page=${pageInfo.activePage}`, {
				withCredentials: true,
			})

			setData(data.data)
		} else {
			let searchQuery = formSearchQuery()
			const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/boontanjai/order/search?${searchQuery}&page=${pageInfo.activePage}`, {
				withCredentials: true,
			})

			setData(data.data)
		}
		setIsLoading(false)
	}

	const getPaymentStatus = (cell) => {
		switch (cell) {
			case "successful":
				return "ชำระเงินสำเร็จ"
			case "pending":
				return "รอการชำระเงิน"
			case "expired":
				return "หมดเวลาชำระเงิน"
			case "failed":
				return "ชำระเงินไม่สำเร็จ"
			case "reversed":
				return "คืนเงิน"
			default:
				return "-"
		}
	}
	const getStatus = (cell) => {
		switch (cell) {
			case "failed":
				return "ไม่สำเร็จ"
			case "pending":
				return "ยังไม่กรอกข้อมูล"
			case "filled":
				return "รอดำเนินการ"
			case "processing":
				return "กำลังดำเนินการ"
			case "unexpected":
				return "เลื่อนดำเนินการ"
			case "forced":
				return "ระบบกำหนดรอบให้"
			case "successful":
				return "สำเร็จ"
			default:
				return "-"
		}
	}
	const getFulfillmentStatus = (cell) => {
		switch (cell) {
			case "failed":
				return "ไม่สำเร็จ"
			case "pending":
				return "ยังไม่กรอกข้อมูล"
			case "filled":
				return "รอดำเนินการ"
			case "processing":
				return "กำลังดำเนินการ"
			case "unexpected":
				return "เลื่อนดำเนินการ"
			case "forced":
				return "ระบบกำหนดรอบให้"
			case "successful":
				return "สำเร็จ"
			default:
				return "-"
		}
	}
	const getShipmentStatus = (cell) => {
		switch (cell) {
			case "pending":
				return "รอดำเนินการ"
			case "processing":
				return "กำลังดำเนินการ"
			case "successful":
				return "สำเร็จ"
			default:
				return "-"
		}
	}

	const getDateFormatterAdd7 = (cell) => {
		if (!cell || cell === "-") {
			return "-"
		}
		return (
			<div>
				<Moment format="YYYY-MM-DD H:mm:ss">{cell}</Moment> +0700
			</div>
		)
	}

	const searchButton = async () => {
		let searchQuery = formSearchQuery()
		setIsSearch(true)

		setIsLoading(true)
		const { data } = await axios.get(`${process.env.REACT_APP_API_ROOT}/admin/boontanjai/order/search?${searchQuery}`, { withCredentials: true })
		if (data.data !== false) {
			setTotalCount(data.totalCount)
			setallPage(data.pages)
			setActivePage(data.currentPage)
			setData(data.data)
		} else {
			setData([])
		}
		setIsLoading(false)
	}

	const openModalExport = async () => {
		setModalExport(true)
	}

	const handleClick = () => {
		if (!createdateGte || !createdateLt) {
			alert("ไม่มีข้อมูล กรุณาเลือกช่วงเวลาหรือกำหนดข้อมูลที่ต้องการใหม่")
			return
		}

		const params = {
			startDate: createdateGte,
			endDate: createdateLt,
		}

		setIsLoading(true)
		axios
			.get(`${process.env.REACT_APP_API_ROOT}/admin/boontanjai/check-pdf`, { params: params, withCredentials: true })
			.then((response) => {
				if (response.data.message === "PDF files found") {
					const urlParams = new URLSearchParams(params).toString()
					const downloadUrl = `${process.env.REACT_APP_API_ROOT}/admin/boontanjai/download-zip?${urlParams}`
					window.location.href = downloadUrl
				} else {
					alert("ไม่มีข้อมูล กรุณาเลือกช่วงเวลาหรือกำหนดข้อมูลที่ต้องการใหม่")
				}
			})
			.catch((error) => {
				console.error(error)
				alert("ไม่มีข้อมูล กรุณาเลือกช่วงเวลาหรือกำหนดข้อมูลที่ต้องการใหม่")
			})
		setIsLoading(false)
	}

	return (
		<>
			<div className="card shadow mb-4">
				<div className="card-header py-3">
					<h6 className="m-0 font-weight-bold text-primary">รายชื่อสมาชิก</h6>
				</div>
				<br />
				<div className="card-body">
					<div className="container d-flex">
						<div className="row col-8">
							<div className="input-group md-form form-sm form-2 pl-0">
								<input
									className="form-control my-0 py-1 lime-border w-100"
									type="text"
									placeholder="ค้นหา Transaction ID, ชื่อ-นามสกุล, เบอร์โทร, อีเมล, line id, sku"
									aria-label="Search"
									value={searchAll}
									onChange={(e) => setSearchAll(e.target.value)}
								/>
							</div>
						</div>
						<button className="btn btn-success btn-icon-split" onClick={() => searchButton()}>
							<span className="icon text-white-50">
								<i className="fas fa-search"></i>
							</span>
							<span className="text">ค้นหาข้อมูล</span>
						</button>
					</div>

					<br />

					<div class="container">
						<div class="card card-body" id="collapseExample">
							<div className="row">
								<div className="col-3">Create Date From</div>
								<div className="col-3">Create Date To</div>
							</div>
							<div className="row">
								<div className="col-3">
									<div className="row">
										<div className="col-12">
											<DateTime
												className="w-100"
												style={{ borderColor: "#ccc" }}
												dateFormat="YYYY-MM-DD"
												timeFormat={false}
												defaultValue={createdateGte}
												onChange={(date) => {
													carlendarcreatedateGte(moment(date).format("YYYY-MM-DD"))
												}}
											/>
										</div>
									</div>
								</div>
								<div className="col-3">
									<div className="row">
										<div className="col-12">
											<DateTime
												className="w-100"
												style={{ width: "100%", borderColor: "#ccc" }}
												dateFormat="YYYY-MM-DD"
												timeFormat={false}
												defaultValue={createdateLt}
												onChange={(date) => {
													const dateFormat = moment(date).set({ hour: 23, minute: 59, second: 59 }).format("YYYY-MM-DD HH:mm:ss")
													carlendarcreatedateLt(dateFormat)
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-3">Parnter</div>
								<div className="col-3">Fulfillment Status</div>
								<div className="col-3">Shipment Status</div>
								<div className="col-3">Payment status</div>
							</div>
							<div className="row">
								<div className="col-3">
									<div className="row">
										<div class="col-12">
											<div class="input-group">
												<select
													class="custom-select"
													id="inputGroupSelect01"
													onChange={(e) => {
														setPartner(e.target.value)
													}}
												>
													<option value="all" selected>
														partner ทั้งหมด
													</option>
													<option value="Sattha">Sattha</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="col-3">
									<div className="row">
										<div class="col-12">
											<div class="input-group">
												<select
													class="custom-select"
													id="inputGroupSelect02"
													onChange={(e) => {
														setFulfillmentStatus(e.target.value)
													}}
												>
													<option value="all" selected>
														รายการทั้งหมด
													</option>
													<option value="successful">สำเร็จ</option>
													<option value="processing">กำลังดำเนินการ</option>
													<option value="unexpected">เลื่อนดำเนินการ</option>
													<option value="forced">ระบบกำหนดรอบให้</option>
													<option value="filled">รอดำเนินการ</option>
													<option value="pending">ยังไม่กรอกข้อมูล</option>
													<option value="failed">ไม่สำเร็จ</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="col-3">
									<div className="row">
										<div class="col-12">
											<div class="input-group">
												<select
													class="custom-select"
													id="inputGroupSelect03"
													onChange={(e) => {
														setShipmentStatus(e.target.value)
													}}
												>
													<option value="all" selected>
														รายการทั้งหมด
													</option>
													<option value="successful">สำเร็จ</option>
													<option value="processing">กำลังดำเนินการ</option>
													<option value="pending">รอดำเนินการ</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="col-3">
									<div className="row">
										<div class="col-12">
											<div class="input-group">
												<select
													class="custom-select"
													id="inputGroupSelect04"
													onChange={(e) => {
														setPaymentStatus(e.target.value)
													}}
												>
													<option value="all" selected>
														รายการทั้งหมด
													</option>
													<option value="successful">ชำระเงินสำเร็จ</option>
													<option value="pending">รอการชำระเงิน</option>
													<option value="expired">หมดเวลาชำระเงิน</option>
													<option value="failed">ชำระเงินไม่สำเร็จ</option>
													<option value="reversed">คืนเงิน</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<br />
					<div className="row" style={{ marginBottom: "8px" }}>
						<div className="col-10">
							<>
								{isLoading ? (
									<Fragment>
										<button className="btn btn-secondary" style={{ width: "120px", height: "41px" }}>
											<Spinner animation="border" role="status" variant="success">
												<span className="sr-only">Loading...</span>
											</Spinner>
										</button>
									</Fragment>
								) : (
									<>
										<button className="btn btn-secondary" onClick={openModalExport} style={{ minWidth: "120px", height: "41px " }}>
											Export to CSV
										</button>
										&nbsp;
										{data.length !== 0 && (
											<button className="btn btn-secondary" onClick={handleClick} style={{ minWidth: "120px", height: "41px " }}>
												Download ใบกํากับภาษี
											</button>
										)}
									</>
								)}
							</>
						</div>
						<div class="col-2">จำนวนข้อมูล {totalCount}</div>
					</div>
					<BootstrapTable data={data} className="table table-striped">
						<TableHeaderColumn row="0" rowSpan="2" dataField="ref_id" width="200" isKey>
							Transaction ID
						</TableHeaderColumn>
						{/* <TableHeaderColumn row="0" rowSpan="2" dataField="partner" width="150">
							Partner
						</TableHeaderColumn> */}
						<TableHeaderColumn row="0" rowSpan="2" dataField="sku" width="200">
							รหัส SKU (SKU Mastercode)
						</TableHeaderColumn>
						{/* <TableHeaderColumn row="0" rowSpan="2" dataField="product_name" width="200">
							SKUMasterName
						</TableHeaderColumn> */}
						{/* <TableHeaderColumn row="0" rowSpan="2" dataField="package_code" width="150">
							Package Code
						</TableHeaderColumn> */}
						{/* <TableHeaderColumn row="0" rowSpan="2" dataField="customer_id" width="150">
							Customer ID
						</TableHeaderColumn> */}
						<TableHeaderColumn row="0" rowSpan="2" dataField="customer_full_name" width="150">
							ชื่อ-นามสกุล
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="2" dataField="package_name" width="200">
							ชื่อแพ็คเกจ
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="2" dataField="product_name" width="150">
							ชื่อชุดถวาย
						</TableHeaderColumn>
						{/* <TableHeaderColumn row="0" rowSpan="2" dataField="customer_name" width="150">
							ชื่อ (ภาษาไทย)
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="2" dataField="customer_surname" width="150">
							นามสกุล (ภาษาไทย)
						</TableHeaderColumn> */}
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="customer_line_name" width="150">
								Line Name
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="customer_email" width="200">
								อีเมล
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="customer_phone" width="150">
								เบอร์โทร
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="customer_birth_date" width="150">
								วัน-เดือน-ปีเกิด
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="link_order" width="150" dataFormat={Link}>
								Sattha Link (Order)
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="link_prove" width="200" dataFormat={Link}>
								Sattha Link (Success)
							</TableHeaderColumn>
						)}
						<TableHeaderColumn row="0" rowSpan="2" dataField="total" width="100">
							ราคา
						</TableHeaderColumn>
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="cost_total" width="150">
								ต้นทุนชุดถวาย
								<br />
								(Cost + VAT7%)
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="fee" width="100">
								Fee
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="fee_vat" width="100">
								Fee Vat
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="net_value" width="150">
								Net Revenue
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="profit" width="100">
								Profit
								<br />
								(กำไร)
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="thairath_revenue" width="150">
								Revenue Sharing
								<br />
								(Thairath-THB)
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="sattha_revenue" width="160">
								Revenue Sharing
								<br />
								(Sattha Online-THB)
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="profit_sharing_before_vat" width="150">
								Profit Sharing
								<br />
								(ก่อน VAT)
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="profit_sharing_vat" width="100">
								VAT 7%
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="profit_sharing_wht" width="100">
								WHT 3%
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="profit_sharing" width="150">
								Billing
								<br />
								(Profit Sharing)
							</TableHeaderColumn>
						)}
						{checkPermission(user.permission, ["business_development_edit", "finance_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="sattha_payout" width="150">
								Sattha Payout
								<br />
								(ชำระเงินให้ศรัทธา)
							</TableHeaderColumn>
						)}
						<TableHeaderColumn row="0" rowSpan="2" dataField="payment_status" width="150" dataFormat={getPaymentStatus}>
							สถานะการชำระเงิน
						</TableHeaderColumn>
						{/* {checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && ()} */}
						<TableHeaderColumn row="0" colSpan="2" dataAlign="center">
							สถานะการสั่งซื้อ
						</TableHeaderColumn>
						{/* <TableHeaderColumn row="1" dataField="status" width="150" dataAlign="center" dataFormat={getStatus}>
							สถานะรวม
						</TableHeaderColumn> */}
						<TableHeaderColumn row="1" dataField="fulfillment_status" width="150" dataAlign="center" dataFormat={getFulfillmentStatus}>
							สถานะขอพร
						</TableHeaderColumn>
						<TableHeaderColumn row="1" dataField="shipment_status" width="150" dataAlign="center" dataFormat={getShipmentStatus}>
							สถานะส่งของ
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="2" dataField="createDate" width="200" dataFormat={getDateFormatterAdd7}>
							วันที่สร้างคำสั่งซื้อ
						</TableHeaderColumn>
						<TableHeaderColumn row="0" rowSpan="2" dataField="payment_date" width="200" dataFormat={getDateFormatterAdd7}>
							วันที่ชำระเงิน
						</TableHeaderColumn>
						{checkPermission(user.permission, ["business_development_edit", "customer_service_edit", "admin"], "or") && (
							<TableHeaderColumn row="0" rowSpan="2" dataField="invoice_pdf" width="100" dataFormat={LinkInvoicePDF}>
								PDF Invoice
							</TableHeaderColumn>
						)}
					</BootstrapTable>
					{data.length !== 0 && <Pagination activePage={activePage} onPageChange={pagination} totalPages={allPage} ellipsisItem={null} />}
				</div>
			</div>
			{modalExport && <ModalExport formSearchQuery={formSearchQuery} setModalExport={setModalExport} />}
		</>
	)
}
export default BoontanjaiDashboard
